<template>
  <div>

    <SalesStateTemplateEditorModal 
      ref="sales-state-modal"
      :item="selected_item"
      :items="items"
      @updated="updated_state_template"
      @created="created_state_template"
    />
    
    <div class="flex-container">
    
      <div class="flex-end justify-content-end">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2"
          @click.prevent="create_state_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SALES.STATE_TEMPLATE.CREATE') }}</a
        >
      </div>
      
    </div>
    

    <b-row>
      <b-col cols="4" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="8">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px; margin-bottom: 6px; height: 32px; padding: 6px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="sales-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(actions)="{ item }">
        <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="select_item_clicked(item)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Write.svg" />
          </span>
        </a>

        <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="delete_item_clicked(item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Trash.svg" />
          </span>
        </a>
      </template>
    </b-table>

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { upload_file } from '@/core/services/fileUpload';
import { mapGetters } from 'vuex';
import { get_sales_filters, set_sales_filters } from '@/core/services/sales';


import axios from 'axios';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import SalesStateTemplateEditorModal from './SalesStateTemplateEditorModal.vue';


export default {
  name: 'SalesStateTemplateTable',
  components: {
    Multiselect,
    SalesStateTemplateEditorModal
  },
  mixins: [ toasts ],
  computed: {

    state_template_options() {

      const base = [
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.NONE'), value: 'NONE' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.START'), value: 'START' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.ASSIGN_SALE_PROSPECT'), value: 'ASSIGN_SALE_PROSPECT' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.FIND_WEBSITES'), value: 'FIND_WEBSITES' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.CRAWL_WEBSITE'), value: 'CRAWL_WEBSITE' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.FIND_CONTACTS'), value: 'FIND_CONTACTS' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.CATEGORIZE_SALES'), value: 'CATEGORIZE_SALES' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.AUTHOR_INTO_EMAIL'), value: 'AUTHOR_INTO_EMAIL' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.SEND_INTRO_EMAIL'), value: 'SEND_INTRO_EMAIL' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.WAITING_REPLY_EMAIL'), value: 'WAITING_REPLY_EMAIL' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.UPDATE_STATUS'), value: 'UPDATE_STATUS' },
      ];

      return base;
    },

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'currentUser', 'isTHS']),
    
    sales_status_options_mult() {
      return this.sales_status_options.map(item => ({ label: item.text, value: item.value }));
    },
    sales_status_options() {
      return [
        { text: this.$t('SALES.SALES_STATUSES.PROSPECT'), value: 'PROSPECT' },
        { text: this.$t('SALES.SALES_STATUSES.DEAD'), value: 'DEAD' },
        { text: this.$t('SALES.SALES_STATUSES.NOT_ANSWER'), value: 'NOT_ANSWER' },
        { text: this.$t('SALES.SALES_STATUSES.DECLINED'), value: 'DECLINED' },
        { text: this.$t('SALES.SALES_STATUSES.OPPORTUNITY'), value: 'OPPORTUNITY' },
        { text: this.$t('SALES.SALES_STATUSES.CLOSING'), value: 'CLOSING' },
        { text: this.$t('SALES.SALES_STATUSES.SIGNED'), value: 'SIGNED' },
        { text: this.$t('SALES.SALES_STATUSES.LIVE'), value: 'LIVE' },
      ]
    },
    company_type_options_mult() {
      return this.company_type_options.map(item => ({ label: item.text, value: item.value }));
    },
    company_type_options() {
      return [
        { text: this.$t('SALES.SALES_COMPANY_TYPES.IDEELL'), value: 'IDEELL' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.TROSSAMFUND'), value: 'TROSSAMFUND' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.STUDENT'), value: 'STUDENT' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.AKASSA'), value: 'AKASSA' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.EKONOMISK_FORENING'), value: 'EKONOMISK_FORENING' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.OTHER_STIFTELSER'), value: 'OTHER_STIFTELSER' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.SAMFALLIGHET'), value: 'SAMFALLIGHET' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.UNKNOWN'), value: 'UNKNOWN' },
      ]
    },
    decline_reason_options() {
      return [
        { text: this.$t('SALES.DECLINE_REASONS.BAD_LEAD'), value: 'BAD_LEAD' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_MONEY'), value: 'NO_MONEY' },
        { text: this.$t('SALES.DECLINE_REASONS.TOO_EXPENSIVE'), value: 'TOO_EXPENSIVE' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_INTEREST'), value: 'NO_INTEREST' },
        { text: this.$t('SALES.DECLINE_REASONS.SATISFIED_COMPETITOR'), value: 'SATISFIED_COMPETITOR' },
        { text: this.$t('SALES.DECLINE_REASONS.LACKS_FEATURES'), value: 'LACKS_FEATURES' },
        { text: this.$t('SALES.DECLINE_REASONS.UNKNOWN'), value: 'UNKNOWN' },
      ]
    }
  },
  props: [],
  emits: ['on_select_item', 'on_delete_item'],
  mounted() {
    this.filters = get_sales_filters()

    this.load_state_templates();

    this.load_users_for_company(this.currentCompanyId);
  },
  data() {
    return {
      selected_item: {},
      items: [],
      filters: {},
      company_type_option: null,
      headers: [
        {
          label: this.$t('SALES.STATE_TEMPLATE.NAME'),
          sortable: true,
          key: 'name',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('SALES.STATE_TEMPLATE.TYPE'),
          sortable: true,
          key: 'type',
          tdClass: 'td-overflow',
          thClass: 'td-overflow',
          formatter: (_, __, item) => {
            return this.state_template_options.find(i => i.value === item.type)?.text || '';
          },
        },
        {
          label: this.$t('SALES.STATE_TEMPLATE.NEXT_STATE'),
          sortable: true,
          key: 'next_success_state_template_id',
          tdClass: 'td-overflow',
          thClass: 'td-overflow',
          formatter: (_, __, item) => {
            return this.items.find(i => i.id === item.next_success_state_template_id)?.name || '';
          },
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right',
        }
      ],
      total_rows: 0,
      current_page: 1,
      per_page: 100,
      search: null,
      user_options: []
    };
  },
  watch: {
    items() {
      this.total_rows = this.items.length;
    },
    filters: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        set_sales_filters(val);
      },
    },
  },
  methods: {
    async load_state_templates() {
      const res = await axios.get(`/sales/state/templates`);

      if (res.status === 200) {
        this.items = res.data;
      }
    },
    create_state_clicked() {
      this.selected_item = {};
      this.$refs['sales-state-modal'].show();
    },
    created_state_template(item) {
      this.items.unshift(item);

      this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.STATE_TEMPLATE.CREATED'));
    },
    updated_state_template(item) {
      const index = this.items.findIndex(i => i.id === item.id);

      if (index >= 0) {
        this.items[index] = item;
        this.items = [...this.items];
      }
    },
    
    async load_users_for_company(company_id) {
      this.user_options = [];
      
      const res = await axios.get(`/access/users?company_id=${company_id}`);

      if (res.status === 200) {
        this.user_options = res.data.map((item) => ({ text: item.email, value: item.user_id }));
        this.user_options.unshift({ text: this.$t('COMMON.ALL'), value: null });
      }
    },


    select_item_clicked(item) {
      this.selected_item = item;
      this.$refs['sales-state-modal'].show();
    },
    async delete_item_clicked(id) {
      const res = await axios.delete(`/sales/state/template/${id}`);
      
      if (res.status === 204) {
        this.items = this.items.filter(item => item.id !== id);
      }
      
    },

    get_filters_string() {
      let url_filters = '';

      if (this.filters.text) {
        url_filters += `&text=${encodeURIComponent(this.filters.text)}`;
      }

      if (this.filters.created_from) {
        url_filters += `&created_from=${this.filters.created_from}`;
      }

      if (this.filters.created_to) {
        url_filters += `&created_to=${this.filters.created_to}`;
      }

      if (this.filters.statuses) {
        url_filters += `&statuses=${encodeURIComponent('[' + this.filters.statuses.map(x => `"${x}"`).join(',') + ']')}`;
      }

      if (this.filters.types) {
        url_filters += `&types=${encodeURIComponent('[' + this.filters.types.map(x => `"${x}"`).join(',') + ']')}`;
      }

      if (this.filters.user_id) {
        url_filters += `&user_id=${this.filters.user_id}`;
      }
      
      return url_filters;
    },

    get_pagination(callback) {

      axios
        .get(`/sales?page=${this.current_page}&limit=${this.per_page}${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination: invalid status', res.status);
            return;
          }

          if (callback) {
            callback(res.data || []);
          }
        })
        .catch(err => {
          console.error('pagination error', err);
        });
    },

    get_count() {
      axios
        .get(`/sales/count?${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination count: invalid status', res.status);
            return;
          }

          this.total_rows = res.data.count;

        })
        .catch(err => {
          console.error('pagination count error', err);
        });
    },

    ajax_pagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_count();
      this.get_pagination(callback);

      return null;
    },

    
  }
};
</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
  
</style>

