<template>
  <div class="container">

    <memlist-modal
      size="sm"
      ref="edit-form"
      :visible="show_modal" @close="show_modal = false"
      hide-footer
      >

      <memlist-text-input
        id="name"
        name="name"
        :title="$t('SALES.AGENT.NAME')"
        layout="vertical"
        v-model="local_item.name"
      />

      <memlist-text-input
        id="email"
        name="email"
        :title="$t('SALES.AGENT.EMAIL')"
        layout="vertical"
        v-model="local_item.email"
      />

      <memlist-textarea
        id="prompt_instructions"
        name="prompt_instructions"
        :title="$t('SALES.AGENT.PROMPT_INSTRUCTIONS')"
        layout="vertical"
        v-model="local_item.prompt_instructions"
        :autoexpand="true"
        type="textarea"
      />
      
      <memlist-select-input2
        id="type"
        name="type"
        :title="$t('SALES.AGENT.TYPE')"
        layout="vertical"
        v-model="local_item.type"
        :items="agent_type_options"
        :required="true"
      />
      
      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="save"
        @close="$refs['edit-form'].hide()"
        ref="saveButton"
      />

    </memlist-modal>

  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'SalesStateEditorModal',

  props: ['item'],
  emits: [],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
  },

  watch: {
    item: {
      handler(val) {
        this.local_item = { ...this.item };
      },
      immediate: true
    }
  },

  computed: {
    
    agent_type_options() {

      const base = [
        { text: this.$t('SALES.AGENT.TYPES.AI'), value: 'AI' },
        { text: this.$t('SALES.AGENT.TYPES.HUMAN'), value: 'HUMAN' },
      ];

      return base;
    },

    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    created(data) {
      this.$refs['saveButton'].stop();
      this.hide();

      this.$emit('created', data);
    },

    async save() {

      if (!this.local_item.id) {
        const res = await axios.post(`/sales/agent`, this.local_item);
        if (res.status === 201) {
          this.local_item = res.data;
          this.$emit('created', this.local_item);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.AGENT.UNABLE_CREATE'));
        }
      }
      else {
        const res = await axios.put(`/sales/agent/${this.local_item.id}`, this.local_item);
        if (res.status === 200) {
          this.$emit('updated', this.local_item);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.AGENT.UNABLE_UPDATE'));
        }
      }

      this.$refs['saveButton'].stop();
      this.hide();
    },

    show() {
      this.show_modal = true;
    },

    hide() {
      this.show_modal = false;
    },

    updated(item) {
      this.hide();
    }
  },

  mounted() {
    
  },

  data() {
    return {
      local_item: {},
      show_modal: false,
      creating: true,
    };
  }
};

</script>

<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
