<template>
  <div class="container">

    <memlist-modal
      size="sm"
      ref="edit-form"
      :visible="show_modal" @close="show_modal = false"
      hide-footer
      >

      <memlist-text-input
        id="name"
        name="name"
        :title="$t('SALES.STATE_TEMPLATE.NAME')"
        layout="vertical"
        v-model="local_item.name"
      />


      <memlist-textarea
        id="prompt_instructions"
        name="prompt_instructions"
        :title="$t('SALES.STATE_TEMPLATE.PROMPT_INSTRUCTIONS')"
        layout="vertical"
        v-model="local_item.prompt_instructions"
        :autoexpand="true"
        type="textarea"
      />

      <memlist-text-input
        id="time_delay_sec"
        name="time_delay_sec"
        :title="$t('SALES.STATE_TEMPLATE.TIME_DELAY_SEC')"
        layout="vertical"
        v-model="local_item.time_delay_sec"
      />
      

      <memlist-select-input2
        id="type"
        name="type"
        :title="$t('SALES.STATE_TEMPLATE.TYPE')"
        layout="vertical"
        v-model="local_item.type"
        :items="state_template_type_options"
        :required="true"
      />

      <memlist-select-input2
        id="status"
        name="status"
        :title="$t('SALES.STATE_TEMPLATE.STATUS')"
        layout="vertical"
        v-model="local_item.status"
        :items="status_options"
        :required="true"
      />

      <memlist-text-input
        v-if="local_item.type === 'SEND_INTRO_EMAIL'"
        id="subject"
        name="subject"
        :title="$t('SALES.STATE_TEMPLATE.SUBJECT')"
        layout="vertical"
        v-model="local_item.subject"
      />

      <memlist-text-input
        v-if="local_item.type === 'UPDATE_VALUE'"
        id="update_value"
        name="update_value"
        :title="$t('SALES.STATE_TEMPLATE.UPDATE_VALUE')"
        layout="vertical"
        v-model="local_item.update_value"
      />

      <memlist-select-input2
        id="next_success_state_template_id"
        name="next_success_state_template_id"
        :title="$t('SALES.STATE_TEMPLATE.NEXT_STATE')"
        layout="vertical"
        v-model="local_item.next_success_state_template_id"
        :items="other_states_options"
        :required="true"
      />

      <memlist-select-input2
        id="template_id"
        name="template_id"
        :title="$t('SALES.AGENT.EMAIL_TEMPLATE_ID')"
        layout="vertical"
        v-model="local_item.template_id"
        :items="email_template_options"
      />
      
      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="save"
        @close="show_modal = false"
        ref="saveButton"
      />

    </memlist-modal>

  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'SalesStateTemplateEditorModal',

  props: ['item','items'],
  emits: [],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
  },
  
  watch: {
    item: {
      handler(val) {
        this.local_item = { ...this.item };
      },
      immediate: true
    }
  },

  computed: {

    other_states_options() {
      return this.items.map(item => ({ text: item.name, value: item.id }));
    },
    
    status_options() {
      const base = [
        { text: this.$t('SALES.STATE_TEMPLATE.STATUSES.PAUSED'), value: 'PAUSED' },
        { text: this.$t('SALES.STATE_TEMPLATE.STATUSES.ACTIVE'), value: 'ACTIVE' },
        { text: this.$t('SALES.STATE_TEMPLATE.STATUSES.ERROR'), value: 'ERROR' },
      ];
      return base;
    },

    state_template_type_options() {

      const base = [
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.NONE'), value: 'NONE' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.START'), value: 'START' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.ASSIGN_SALE_PROSPECT'), value: 'ASSIGN_SALE_PROSPECT' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.FIND_WEBSITES'), value: 'FIND_WEBSITES' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.CRAWL_WEBSITE'), value: 'CRAWL_WEBSITE' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.FIND_CONTACTS'), value: 'FIND_CONTACTS' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.CATEGORIZE_SALES'), value: 'CATEGORIZE_SALES' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.SEND_INTRO_EMAIL'), value: 'SEND_INTRO_EMAIL' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.WAITING_REPLY_EMAIL'), value: 'WAITING_REPLY_EMAIL' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.PARSE_AND_SEND_REPLY_EMAIL'), value: 'PARSE_AND_SEND_REPLY_EMAIL' },
        { text: this.$t('SALES.STATE_TEMPLATE.TYPES.UPDATE_STATUS'), value: 'UPDATE_STATUS' },
      ];
      return base;
    },

    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    async load_template_options() {
      const res = await axios.get(`/template?company_id=${this.currentCompanyId}`);

      if (res.status === 200) {
        this.email_template_options = res.data.map(item => ({ text: item.name, value: item.template_id }));
      }
    },

    created(data) {
      this.$refs['saveButton'].stop();
      this.hide();

      this.$emit('created', data);
    },

    async save() {

      if (!this.local_item.id) {
        if (!this.local_item.status) {
          this.local_item.status = 'PAUSED';
        }

        const res = await axios.post(`/sales/state/template`, this.local_item);
        if (res.status === 201) {
          this.local_item = res.data;
          this.$emit('created', this.local_item);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.STATE_TEMPLATE.UNABLE_CREATE'));
        }
      }
      else {
        const res = await axios.put(`/sales/state/template/${this.local_item.id}`, this.local_item);
        if (res.status === 200) {
          this.$emit('updated', this.local_item);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.STATE_TEMPLATE.UNABLE_UPDATE'));
        }
      }

      this.$refs['saveButton'].stop();
      this.hide();
    },


    show() {
      this.show_modal = true;
    },

    hide() {
      this.show_modal = false;
    },

    updated(item) {
      this.hide();
    },

    async fetch_state_templates() {
      const res = await axios.get(`/sales/state/templates`);

      if (res.status === 200) {
        this.state_template_options = res.data.map(item => ({ text: item.name, value: item.id }))
      }
    }
  },

  mounted() {
    this.load_template_options();
  },

  data() {
    return {
      email_template_options: [],
      local_item: {},
      state_template_options: [],
      show_modal: false,
      creating: true,
    };
  }
};

</script>

<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
