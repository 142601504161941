<template>
  <div class="container">

    <memlist-modal
      size="sm"
      ref="edit-form"
      :visible="show_modal" @close="show_modal = false"
      hide-footer
      >

      <memlist-text-input
        id="created_at"
        name="created_at"
        :title="$t('SALES.EMAIL.CREATED_AT')"
        layout="vertical"
        v-model="local_item.created_at"
      />

      <memlist-text-input
        id="subject"
        name="subject"
        :title="$t('SALES.EMAIL.SUBJECT')"
        layout="vertical"
        v-model="local_item.subject"
      />

      <memlist-text-input
        id="to_email"
        name="to_email"
        :title="$t('SALES.EMAIL.TO_EMAIL')"
        layout="vertical"
        v-model="local_item.to_email"
      />

      <memlist-text-input
        id="tracking_str"
        name="tracking_str"
        :title="$t('SALES.EMAIL.TRACKING_STR')"
        layout="vertical"
        v-model="local_item.tracking_str"
      />

      <memlist-textarea
        id="content"
        name="content"
        :title="$t('SALES.EMAIL.CONTENT')"
        layout="vertical"
        v-model="local_item.content"
        :autoexpand="true"
        type="textarea"
      />
      
      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="save"
        @close="$refs['edit-form'].hide()"
        ref="saveButton"
      />

    </memlist-modal>

  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


export default {

  name: 'SalesAgentEmailModal',

  props: ['item'],
  emits: [],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
  },

  watch: {
    item: {
      handler(val) {
        this.local_item = { ...this.item };
      },
      immediate: true
    }
  },
  
  computed: {
    
    agent_type_options() {

      const base = [
        { text: this.$t('SALES.AGENT.TYPES.AI'), value: 'AI' },
        { text: this.$t('SALES.AGENT.TYPES.HUMAN'), value: 'HUMAN' },
      ];

      return base;
    },

    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    created(data) {
      this.$refs['saveButton'].stop();
      this.hide();

      this.$emit('created', data);
    },

    save() {
      this.$refs['saveButton'].stop();
      this.hide();
    },

    show() {
      this.show_modal = true;
    },

    hide() {
      this.show_modal = false;
    },

    updated(item) {
      this.hide();
    }
  },

  mounted() {
    
  },

  data() {
    return {
      local_item: {},
      show_modal: false,
      creating: true,
    };
  }
};

</script>

<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
